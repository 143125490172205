/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import "./userGroups.scss";
import { UserGroup, UserGroupPropsInterface } from "./userGroupsInterface";

export function UserGroups({ userGroups, triStateVal, setTriStateVal, userGroupToggle }: UserGroupPropsInterface) {
  const [userGroupProps, setUserGroupProps] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  const handleTriState = () => {
    const checkUgs = userGroups.filter((ug: UserGroup) => ug.checked);
    if (!checkUgs.length) {
      setTriStateVal(null);
    } else if (checkUgs.length === userGroups.length) {
      setTriStateVal(true);
    } else {
      setTriStateVal(false);
    }
  };

  const sortAndSet = (userGroupEdit: UserGroup[]) => {
    // sort first by name
    userGroupEdit = userGroupEdit.sort((a: UserGroup, b: UserGroup) => (a.name < b.name ? 1 : -1));
    // then by checked status
    userGroupEdit = userGroupEdit.sort((a: UserGroup, b: UserGroup) => (a.checked < b.checked ? 1 : -1));
    setUserGroupProps(userGroupEdit);
    handleTriState();

    setTimeout(() => {
      containerRef.current.scrollTop = scrollPosition;
    });
  };

  const handleScroll = () => {
    setScrollPosition(containerRef.current.scrollTop);
  };

  const toggleChecked = (userGroupToToggle: UserGroup, ind: number) => {
    const userGroupEdit: UserGroup[] = [...userGroupProps];
    userGroupToToggle.checked = !userGroupToToggle.checked;
    userGroupEdit[ind] = userGroupToToggle;
    userGroupToggle(userGroupToToggle, userGroupEdit);
    sortAndSet(userGroupEdit);
  };

  const triStateChange = (val: boolean | null) => {
    let value = val;
    if (value === false) {
      value = null;
    }
    setTriStateVal(value);
  };

  const searchHandle = (str: string) => {
    const filteredUGs = [...userGroups].filter((ug: UserGroup) => ug.name.toLowerCase().includes(str.toLowerCase()));
    sortAndSet(filteredUGs);
  };

  useEffect(() => {
    setUserGroupProps([...userGroups]);
    sortAndSet([...userGroups]);
    handleTriState();
  }, [userGroups]);

  return (
    <>
      <div className="row right-side-row border-bottom-light">
        <div className="col-11">
          <input className="search-text" type="text" placeholder="Search User Groups..." onChange={(evt) => searchHandle(evt.target.value)} />
        </div>
        <div className="col-1 triStateCheckbox mt-2">
          <TriStateCheckbox className="p-checkbox-t" value={triStateVal} onChange={(e) => triStateChange(e.value)} />
        </div>
      </div>
      <div className="fill-in-search ms-1" ref={containerRef} onScroll={handleScroll}>
        {userGroupProps.map((userGroup: UserGroup, index: number) => (
          <div className="accordionContent" role="presentation" key={`list-${userGroup.id}`} onClick={() => toggleChecked(userGroup, index)}>
            <span>{userGroup.name}</span>
            <input type="Checkbox" className="p-Checkbox ms-2" checked={userGroup.checked} onChange={() => {}} />
          </div>
        ))}
      </div>
    </>
  );
}
